.main-container {
  /* position: relative; */
}
.main-container .component-loader {
  height: calc(100vh - 50px);
  position: absolute;
}
.sub-container {
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 51px);
}
.btn-body {
  background-color: #2196f3;
  border: none;
  width: 200px;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  padding: 10px;
}
.call-status-message {
  display: flex;
  height: calc(100vh - 51px);
  justify-content: center;
  align-items: center;
}
.customer-call-sec {
  width: 100vw;
  height: calc(100vh - 51px);
}
.connecting-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 51px);
}
.message-text,
.text {
  font-size: 22px;
  color: #116366;
  text-align: center;
}
.red-call {
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  border: none;
  color: #fff;
  background-color: red;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.option-button-red {
  display: flex;
  background-color: #97291e;
  color: white;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  z-index: 1000;
}
