.login-sec {
  background-color: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  height: calc(100vh - 62px);
  width: 100%;
  flex-direction: column;
  justify-content: center;
}
.satsur-logo {
  padding: 30px;
}
.login-loader-sec {
  position: relative;
}
.login-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.satsur-logo h2 {
  font-size: 18px;
  color: #053c6d;
  text-align: center;
  margin-top: 10px !important;
}
.satsur-logo img {
  display: flex;
  align-self: center;
  align-items: center;
  width: 250px;
  object-fit: contain;
}
.log-in-sec {
  text-align: center;
  width: 40%;
}
.log-in-form-sec {
  text-align: center;
  width: 100%;
  background-color: #e77817;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 25px;
  margin-bottom: 30px;
}
.form-input-sec {
  margin-bottom: 20px;
}
.form-input-sec label {
  font-size: 14px;
  display: block;
  text-align: left;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
  font-weight: bold;
}
.form-input-sec label span {
  color: #9c2020;
  font-size: 14px;
  vertical-align: top;
  margin-left: 5px !important;
}
.form-input-sec input[type="password"] {
  -webkit-text-security: circle;
}
.form-input-sec .form-control:focus {
  color: #495057;
  background-color: #fff;
  outline: 0;
  border-color: #053c6d;
  outline: 0;
  box-shadow: 0 0 0 0.05rem #053c6d;
}
.form-input-submit {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.form-input-submit label {
  font-size: 14px;
  color: #000000;
  text-align: right;
  vertical-align: bottom;
  width: 22%;
}
.form-input-submit button {
  padding: 5px 20px;
  border: none;
  border-radius: 8px;
  color: #fff;
  background-color: #053c6d;
  display: flex;
  align-items: center;
}
.forget-password-sec {
  float: right;
}
.forget-psw-btn {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-self: flex-end;
  padding: 8px 15px;
  font-size: 16px;
  color: #495057;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 6px;
}
.forget-psw-btn img {
  margin-right: 5px;
  object-fit: contain;
}

.error-msg {
  color: red;
  margin-top: -15px !important;
  vertical-align: top;
  font-size: 15px;
}
.powered-by {
  width: 40%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.powered-by p {
  text-align: right;
  color: #053c6d;
  font-weight: bold;
}

.powered-by img {
  width: 200px;
  margin-left: 10px !important;
}
.login-sec .rapid-sec {
  margin-bottom: 20px;
}
.rapid-sec p {
  color: #053c6d;
  text-align: center;
  font-size: 16px !important;
}
.rapid-sec h6 {
  color: #053c6d;
  text-align: center;
  font-weight: bold !important;
  font-size: 22px !important;
}
.hide-show {
  position: relative;
}
.hide-show img {
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 7px;
  width: 23px;
  height: 23px;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin-right: 10px;
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.loader-cate,
.loader-cate:after {
  border-radius: 50%;
  height: 10em;
}
.loader-cate {
  width: 20px !important;
  position: absolute !important;
  top: 10px;
  left: 0px;
  right: 45%;
  margin: 0px auto;
  margin-right: 10px;
  font-size: 2px;
  text-indent: -9999em;
  border-top: 1.1em solid #e77817;
  border-right: 1.1em solid #e77817;
  border-bottom: 1.1em solid #e77817;
  border-left: 1.1em solid #fff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
