.main-home-container {
  position: relative;
}
.main-home-container .component-loader {
  height: calc(100vh - 50px);
  position: absolute;
}
.sub-home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 75px);
}
.rapid-header {
  box-shadow:
    0 1px 4px rgb(242 127 55),
    inset 0 0 40px rgb(181 102 38);
  background-color: #f37e20;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 5px 5px;
}
.rapid-header label {
  color: #053c6d;
  font-size: 18px;
  line-height: 22px;
}
.card-videoCall {
  border-radius: 10px;
  background-color: #4285f4;
  padding: 20px;
}
.card-image {
  width: 170px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: #003366;
  margin-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.image-png {
  width: 120px;
  height: 120px;
  margin: 0px auto;
}
.image-png img {
  width: 100%;
  height: 100%;
}

.button-text {
  color: white;
  font-size: 18;
  text-align: center;
}

.title-text {
  font-size: 18px;
  text-align: center;
  color: #116366;
}
