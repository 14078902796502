.dashboard-sections {
  height: calc(100vh - 100px);
  margin-top: 50px !important;
}
.landing-page-sec {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 95%;
  margin: 0 auto;
  height: calc(100vh - 100px);
  align-items: center;
  row-gap: 10px;
  column-gap: 10px;
}
.landing-card {
  flex-basis: 20%;
  height: 245px;
  border: 5px solid #f3f3f3 !important;
  transition: box-shadow 0.3s;
}
.landing-card:hover {
  box-shadow:
    rgb(238 125 54) 0px 1px 2px 0px,
    rgb(239 126 54) 0px 2px 6px 2px;
}
.landing-card a {
  text-decoration: none !important;
  color: black !important;
}
.landing-card1:hover {
  text-decoration: none !important;
  color: black !important;
}
.card-img {
  width: 130px !important;
  margin: 0 auto;
}
.card-img-top {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0px auto;
  display: block;
  margin-top: 20px;
}
.card-body {
  text-align: center;
}
.card-text {
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
  color: #152d46;
}
.MuiTableCell-root {
  padding: 8px 18px !important;
}
.adminModal .modal-content {
  height: 57%;
  margin-top: 80px;
}
/* .MuiToolbar-gutters {
  padding-left: 18px !important;
  padding-right: 18px !important;
} */
/* .MuiToolbar-regular {
  min-height: 50px !important;
} */
.MuiTableCell-footer {
  padding: 0px !important;
}
/* .MuiPaper-elevation4 {
  background-color: #e77817 !important;
}
.MuiTableRow-root {
  background-color: #e77817 !important;
} */

/* Responsive */
@media only screen and (max-width: 1200px) {
  .landing-card {
    margin-bottom: 60px;
    flex-basis: 30%;
  }
}
@media only screen and (max-width: 940px) {
  .card-img {
    width: 160px !important;
  }
  .card-text {
    font-size: 16px;
  }
}
