.autocomplete-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.autocomplete-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.suggestions-dropdown {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
}

.suggestions-dropdown li {
  padding: 10px;
  cursor: pointer;
  list-style-type: none;
  transition: background-color 0.2s;
}

.suggestions-dropdown li:hover,
.suggestion-active {
  background-color: #f0f0f0;
}

.no-suggestions {
  padding: 10px;
  color: #999;
}

.acc-container {
  /* margin:20px */
  margin: 20px 0px;
}

.image-normal {
  height: 20px;
  width: 20px;
}

.image-rotate {
  height: 20px;
  width: 20px;
  transform: rotate(90deg) !important;
}

.avm-field .form-control {
  /* Add your styles here */
  background-color: white;
  /* Example */
  border-bottom: 1px solid #ebebeb;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
  font-size: 14;
  font-family: Arial, Helvetica, sans-serif;
}

.avm-field .form-control:focus {
  background-color: white;
  outline: 0;
  outline: 0;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  box-shadow: 0 0 0 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid grey;
}

.acc-title {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 0px 20px;
  /* padding: 10px 20px; */
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
}

.acc-title:hover {
  background-color: #fff5ee;
}

.icic-right-background {
  background-color: #fff5ee;
}

.split-layout {
  margin: 20px 10px;
}

.split-card {
  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.report-download {
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  background-color: #033b6c;
  font-family: Arial, Helvetica, sans-serif;
}

.normal-text {
  color: black !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

.custom-tabs-container {
  height: 65px;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ebebeb;
}

.custom-tab {
  border-bottom: 1px-solid;
  height: 100%;
  width: 108px;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-wrap: wrap;
}

.heiglight {
  position: absolute;
  border-bottom: 3px solid #f47d21;
  min-width: 150px;
  bottom: -2px;
}

.custom-selected-tab {
  color: #f47d21;
  /* border-bottom: 3px solid  #F47D21; */
  height: 100%;
  width: 118px;
  /* font-family: Arial, Helvetica, sans-serif; */
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  text-wrap: wrap;
}

.map-height {
  position: relative;
  max-height: calc(100vh - 196px);
}

.map-text {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 0 20px;
  gap: 10;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Make sure it appears above other content */
}

/* Modal content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  /* width: 400px; */
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  height: 90%;
}

/* Close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.transactions-container {
  margin: 20px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2 {
  margin: 0;
}

.collapse-btn {
  background-color: transparent;
  border: none;
  color: #007bff;
  font-size: 16px;
  cursor: pointer;
}

.collapse-btn:hover {
  text-decoration: underline;
}

.avm {
  width: 100% !important;
}

.avm td {
  font-size: 12px;
  /* Allows text wrapping */
  white-space: nowrap;
  /* Prevents text wrapping to multiple lines */
  overflow: hidden;
  /* Hides overflow */
  text-overflow: ellipsis;
  /* Adds "..." for text overflow */
  max-width: 100px;
}

.avm th {
  background-color: #f6f6f6;
  color: #333 !important;
  font-size: 12px;
}

.avm td:hover {
  text-decoration: underline;
}

.truncate-text {
  white-space: nowrap;
  /* Prevents text wrapping to multiple lines */
  overflow: hidden;
  /* Hides overflow */
  text-overflow: ellipsis;
  /* Adds "..." for text overflow */
  min-width: 100px;
}

.popover-containers {
  position: relative;
  /* Needed to properly position the popover relative to this container */
  display: inline-block;
  /* Ensure the container is sized based on the content */
}

.popovers {
  position: absolute;
  /* Position relative to the container */
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  z-index: 1000;
  width: 200px;
  transition: opacity 0.3s ease;
  opacity: 1;
}

.popovers p {
  margin: 0;
  color: #333;
  font-size: 14px;
}

.popovers::before {
  content: "";
  position: absolute;
  top: -10px;
  /* Adjust based on the popover's position */
  left: 20px;
  /* Adjust the horizontal position of the arrow */
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.title-font {
  font-size: 16px;
}

.text-padding {
  padding: 10px 10px !important;
}

.expand-button {
  text-decoration: underline;
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 10px;
  margin-top: 5px;
  cursor: pointer;
}

.modal-content td {
  overflow: unset;
  white-space: unset;
  text-decoration: none !important;
  max-width: 200px;
}

.modal-content .table-private-data-sub {
  height: calc(100vh - 180px) !important;
}

.modal-content .table-private-data-sub {
  height: calc(100vh - 180px) !important;
}

.acc-inner-content {
  margin: 0px 10%;
}

.avm-field select {
  /* Add your styles here */
  background-color: white;
  /* Example */
  border-bottom: 1px solid #ebebeb;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
  font-size: 14;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0 0 0 0 !important;
}

.lat-log-aligenemnt {
  margin-right: 20px !important;
}

.marker-info {
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: #e77817;
  width: 350px;
}

.marker-info li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.map-legend {
  position: absolute;
  left: 10px;
  bottom: -650px;
  display: flex;
}

.map-legend li {
  border: 2px solid #fff;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  padding: 6px 5px;
  text-align: center;
  margin-right: 10px !important;
  width: 135px;
}

.icici_legend {
  background-color: #01396e;
  margin-right: 10px !important;
}

.pp_legend {
  background-color: #937cba;
}

.mt_legend {
  background-color: #f5868f;
}

.icici {
  background-color: #01396e;
}

.propertyPortals {
  background-color: #937cba;
}

.marketTransation {
  background-color: #f5868f;
}

.marker-toggle {
  position: absolute;
  right: 0px;
  z-index: 9;
  bottom: -210px;
}

.marker-info li label {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  flex-basis: 30%;
}

.marker-info li span {
  border-left: 1px solid #fff;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  flex-basis: 60%;
}

.marker-info li:not(:last-child) {
  border-bottom: 1px solid #fff;
}

.avm-field select:disabled {
  background-color: #e9ecef;
}

.avm-field select:focus {
  background-color: white;
  outline: 0;
  outline: 0;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  box-shadow: 0 0 0 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid grey;
}

.loaderblack,
.loaderblack:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loaderblack {
  margin-right: 10px;
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(17, 17, 17, 0.2);
  border-right: 1.1em solid rgba(62, 61, 61, 0.2);
  border-bottom: 1.1em solid rgba(17, 17, 17, 0.2);
  border-left: 1.1em solid #0b0b0b;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.avm-report .property-detail-content {
  width: 100% !important;
}

.avm-report .card-detail-inr {
  width: 25% !important;
}

.summary-data .card-detail-inr {
  width: 35% !important;
}

.avm-report .property-detail-trend {
  flex-direction: column;
}

.imag-data .property-detail-title {
  border: none !important;
}

.sub-title-propEdge {
  font-size: 12 !important;
  margin-left: 40px;
}

@media print {
  .avm-report .page-break {
    page-break-before: always;
    /* Start a new page before this element */
  }

  header,
  footer {
    position: fixed;
    left: 0;
    right: 0;
    height: 50px;
    /* Set the height of the header/footer */
    background-color: #f8f8f8;
    /* Optional background color */
    text-align: center;
    /* Center text */
    line-height: 50px;
    /* Center vertically */
  }

  header {
    top: 0;
    /* Position header at the top */
  }

  footer {
    bottom: 0;
    /* Position footer at the bottom */
  }
}

.avm-select:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.textwrap {
  text-wrap: auto;
}
