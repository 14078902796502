.dashboard-sections {
  height: calc(100vh - 100px);
  margin-top: 100px !important;
}
.landing-card1:hover {
  text-decoration: none !important;
  color: black !important;
}
.card-img {
  width: 130px !important;
  margin: 0 auto;
}
.card-img-reort {
  width: 40%;
  height: auto;
}
.report-card {
  padding-top: 4%;
  justify-content: center;
  align-items: center !important;
  display: flex;
  flex-direction: column !important;
}
.card-img-top {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 0px auto;
  display: block;
  margin-top: 20px;
}
.card-body {
  text-align: center;
}
.card-text {
  font-size: 18px;
  line-height: 23px;
  font-weight: 700;
  color: #152d46;
}
.landing-page-sec {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 95%;
  margin: 0 auto;
  height: calc(100vh - 100px);
  align-items: center;
}
