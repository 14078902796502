.export-Button button {
  margin-top: 15px;
  padding: 5px 10px;
  border-radius: 6px;
  display: flex;
  font-size: 14px;
  border: none;
  color: #fff;
  background-color: #053c6d;
  cursor: pointer;
  font-weight: bold !important;
  margin-top: 10px;
  margin-top: 25px !important;
}

.exportDate {
  padding: 8px;
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #053c6d;
  border-radius: 6px;
  background: #fff;
  text-transform: capitalize;
  cursor: pointer;
}
