.component-loader {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: rgb(183 183 183 / 42%);
}
.loader-circle {
  position: absolute !important;
  top: 45% !important;
  left: 45% !important;
  border: 4px solid #fff;
  border-left: 4px solid #e77817;
  border-top: 4px solid #e77817;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.6s linear infinite;
  -webkit-animation: spin 1.6s infinite linear;
}
.loader-circle-v1 {
  border: 4px solid #fff;
  border-left: 4px solid #e77817;
  border-top: 4px solid #e77817;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1.6s linear infinite;
  -webkit-animation: spin 1.6s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
