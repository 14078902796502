.overflowHidden {
  overflow-y: scroll !important;
}
.mrg-btm-board {
  margin-bottom: 20px;
  border-bottom: 2px solid #000000;
}
.analytics-sec {
  margin-top: 76px !important;
}
.swap-chart {
  text-align: center;
  margin: 15px 50px !important;
  display: flex;
  justify-content: space-around;
}
.active-chart {
  -webkit-box-shadow: 0 10px 6px -6px #053c6d;
  -moz-box-shadow: 0 10px 6px -6px #053c6d;
  border: 1px solid #053c6d;
  box-shadow: 0 10px 6px -6px #053c6d;
}
.swap-chart label {
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  /* transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); */
  background-color: #e77817;
  color: #053c6d;
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
}
.scrollNon {
  overflow: visible !important;
}
.rate-text {
  text-align: right;
  height: calc(50vh - 58px);
  padding: 0px 20px;
}
.rate-text label {
  color: #053c6d;
  padding: 5px 20px;
}
.chartjs-render-monitor {
  height: 100% !important;
  padding: 20px 0px 0px;
}
.slider {
  width: 95% !important;
  margin: 0px auto;
}
.slick-prev,
.slick-next {
  background: transparent !important;
  top: 50% !important;
}
.slick-next {
  right: 47% !important;
}
.slick-prev {
  left: 47% !important;
}
.chat-data-lay-out {
  height: calc(100vh - 76px);
  /* background-color: #e77817; */
}
/* .slick-disabled { */
/* display: none !important; */
/* } */
/* .chat-data-lay-out {
  display: flex !important;
}
.analytics-container {
  width: 47% !important;
} */

/* Responsive */
@media only screen and (max-width: 1024px) {
  .slick-prev {
    left: 45% !important;
  }
}
