.geo-table-container {
  flex: 1;
}
.tracking-filter {
  display: flex;
  margin-bottom: 15px;
}
.select-picker {
  position: relative;
  width: 209px;
}
.loader-border,
.date-disp,
.select-picker input {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  background: #f2f3f6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 1px solid #e57933;
  padding: 10px;
  width: 209px;
}
.loader-border {
  padding: 18px;
}
.region-select {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  /* display: none; */
}
.region-select select {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  background: #f2f3f6;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 25%);
  border-radius: 5px;
  border: 1px solid #e57933;
  padding: 7px;
  cursor: pointer;
}
.locationtracking-sections {
  padding: 20px 0px;
}
/* .geo-tracking-locations { */
/* position: relative;
  height: 80vh;
  width: 100%;
  border: 1px solid #ee7d36; */
/* } */
.map-sections {
  position: relative;
  height: 80vh;
  width: 100%;
  border: 1px solid #ee7d36;
  /* height: 80vh; */
}
/* locationTrackingCss */

.geo-popup {
  background-color: #e77817;
  padding: 15px !important;
  border-radius: 16px !important;
}
.geo-popup table tbody tr td {
  padding: 5px;
  border: 1px solid #ffffff;
  color: #000000;
  font-weight: bold;
}
.width100 {
  width: 92px;
}
.width150 {
  width: 150px;
}
.tracks {
  width: calc(100vw - 35px);
}
.bank-user-data .MuiTableCell-root span {
  justify-content: center;
}
